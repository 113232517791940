@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-[#2E2E2E] text-white antialiased;
  }
}

@layer utilities {
  .text-gradient {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-[#6C8AE4] to-[#5C4A8D];
  }
}

/* Feature cards animation */
.feature-card {
  opacity: 0;
  transform: translateY(20px);
}

.feature-card.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Intersection Observer for Features */
@media (prefers-reduced-motion: no-preference) {
  .feature-card {
    view-timeline-name: --card;
    view-timeline-axis: block;
    animation: fade-in linear both;
    animation-timeline: --card;
    animation-range: entry 25% cover 50%;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}